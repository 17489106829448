import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HServices = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("our_services")}</span></h1>
                        <div className="text-center">
                            <p><i className="ri-star-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular m2-2'}>{t("quality")}</span></p>
                            <p><i className="ri-star-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular m2-2'}>{t("speed")}</span></p>
                            <p><i className="ri-star-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular m2-2'}>{t("follow_up")}</span></p>
                            <p><i className="ri-star-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular m2-2'}>{t("precision")}</span></p>
                            <p><i className="ri-star-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular m2-2'}>{t("guarantee")}</span></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 text-center">
                    <div className="accordion accordion-flush" id="accordionFlush">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button id="srvbtnone" className="collapsed text-info" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("architecture")}</span>
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <span className={i18n.language === 'en' ? 'montserrat-regular' : 'cairo-regular'}>{t("arch_descp")}</span>
                                    </div>
                             </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                            <button id="srvbtntwo" className="collapsed text-info" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("steel_structure")}</span>
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <span className={i18n.language === 'en' ? 'montserrat-regular' : 'cairo-regular'}>{t("steel_descp")}</span>
                                    </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                            <button id="srvbtnthree" className="collapsed text-info" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                <span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("decor")}</span>
                            </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <span className={i18n.language === 'en' ? 'montserrat-regular' : 'cairo-regular'}>{t("decor_descp")}</span>
                                    </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <h2 className="text-center text-info mt-3"><span className={i18n.language === 'en' ?  'montserrat-bolder': 'cairo-bolder'}>{t("free_quote_today")}</span></h2>
                <p className="text-center text-info"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("free_quote_desc")}</span></p>
                <center><Link to="/contact"><button className="btn btn-secondary mt-2 px-5 hservice-btn"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("free_quote_btn")}</span></button></Link></center>
            </div>
        </>
     );
}
 
export default HServices;